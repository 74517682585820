import React, { useEffect, useState } from 'react';
import { useQueryParamString, getQueryParams } from 'react-use-query-param-string';

import { useProducts } from '@hooks/useProducts';
import { useFilter } from '@hooks/useFilter';
import type { TagItemType } from '@customTypes/tags';
import type { ProductType } from '@customTypes/product';

import Layout from '@components/Layouts/Layout';
import { Header } from '@components/Blocks/Header';
// import { FilteredProductsBlock } from '@components/Blocks/FilteredProductsBlock';
import { Products } from '@components/Blocks/Products';
import { FilterMultiSelect } from '@components/Blocks/FilterMultiSelect';
import { Tabs } from '@components/Blocks/Tabs';
import { Tags } from '@components/Blocks/Tags';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import { MiniServices } from '@components/Blocks/MiniServices';
import SEO from '@components/Layouts/SEO';
import ThinPromo from '@components/Blocks/ThinPromo';

import { event } from '@analytics/gtm';

import {
  allowedCoverTypes,
  allowedPageCounts,
  allowedFormatTypes,
  allowedProductCategories,
} from '@consts/goodsConsts';
import {
  pageCountFilterOptions,
  coverTypeFilterOptions,
  formatTypeFilterOptions,
} from '../../data/filters';

import styles from './index.module.scss';

const tabs = [
  { label: 'Фотокниги', value: 'photobooks' },
  { label: 'Карточки', value: 'other' },
  { label: 'Календари', value: 'calendars' },
  { label: 'Блокноты', value: 'notebooks' },
  // { label: 'Свадьба', value: 'wedding' },
  // { label: 'Детская', value: 'detskaya' },
  // { label: 'Книга традиций', value: 'traditions' },
  { label: 'Sale', value: 'sale' },
];

export function Head() {
  return (
    <>
      <SEO
        title="Продукты типографии"
        description="Храните воспоминания красиво. Фотокниги, открытки, снимки, подарочные сертификаты."
      />
      <YandexTableau />
    </>
  );
}

// TODO: требуется рефакторинг
function ProductsPage() {
  const params = getQueryParams();
  const {
    photobooks,
    otherProducts,
    saleProducts,
    calendarProducts,
    notebookProducts,
    // weddingProducts,
    // detskayaProducts,
    // traditionsBook,
    printsProducts,
  } = useProducts();

  // TODO: думаю, что можно отрефакторить и не держать такой огромный стейт
  const [_categoryType, setCategoryType] = useQueryParamString('category', '');
  const [_pageCount, setPageCount] = useQueryParamString('pageCount', '');
  const [_coverType, setCoverType] = useQueryParamString('coverType', '');
  const [_productFormat, setProductFormat] = useQueryParamString('productFormat', '');

  const [selectedPageCount, setSelectedPageCount] = useState([]);
  const [selectedCoverType, setSelectedCoverType] = useState([]);
  const [selectedProductFormat, setSelectedProductFormat] = useState([]);

  const [selectedPageTags, setSelectedPageTags] = useState<TagItemType[]>([]);
  const [selectedCoverTags, setSelectedCoverTags] = useState<TagItemType[]>([]);
  const [selectedFormatTags, setSelectedFormatTags] = useState<TagItemType[]>([]);

  const [selectedCategory, setSelectedCategory] = useState('photobooks');
  const [selectedProducts, setSelectedProducts] = useState<ProductType[]>(photobooks);

  const { getItemsByProperties } = useFilter(selectedCategory);

  // TODO: filtered products, should rename
  const [items, setItems] = useState<ProductType[]>([]);

  const handleSelectFormatType = (value: string, checked: boolean, isFromUrl = false) => {
    const selectedItem = formatTypeFilterOptions.find((item) => item.value === value);
    if (checked) {
      // @ts-ignore
      setSelectedProductFormat((prev) => [...prev, value]);
      if (selectedItem) {
        setSelectedFormatTags((prev) => [...prev, selectedItem]);
      }
    } else {
      setSelectedProductFormat((prev) => prev.filter((prevFormatType) => prevFormatType !== value));
      if (selectedItem) {
        setSelectedFormatTags((prev) =>
          prev.filter((prevFormatTag) => prevFormatTag !== selectedItem)
        );
      }
    }
    if (!isFromUrl) {
      event(`event_site_filter_formatType`);
    }
  };

  const handleSelectPageCount = (value: string, checked: boolean, isFromUrl = false) => {
    const selectedItem = pageCountFilterOptions[selectedCategory].find(
      (item: TagItemType) => item.value === value
    );
    if (checked) {
      // @ts-ignore
      setSelectedPageCount((prev) => [...prev, value]);
      if (selectedItem) {
        setSelectedPageTags((prev) => [...prev, selectedItem]);
      }
    } else {
      setSelectedPageCount((prev) => prev.filter((prevPageCount) => prevPageCount !== value));
      if (selectedItem) {
        setSelectedPageTags((prev) => prev.filter((prevPageTag) => prevPageTag !== selectedItem));
      }
    }
    if (!isFromUrl) {
      event(`event_site_filter_pageCount`);
    }
  };

  const handleSelectCoverType = (value: string, checked: boolean, isFromUrl = false) => {
    const selectedItem = coverTypeFilterOptions.find((item) => item.value === value);
    if (checked) {
      // @ts-ignore
      setSelectedCoverType((prev) => [...prev, value]);
      if (selectedItem) {
        setSelectedCoverTags((prev) => [...prev, selectedItem]);
      }
    } else {
      setSelectedCoverType((prev) => prev.filter((prevCoverType) => prevCoverType !== value));
      if (selectedItem) {
        setSelectedCoverTags((prev) =>
          prev.filter((prevCoverTag) => prevCoverTag !== selectedItem)
        );
      }
    }
    if (!isFromUrl) {
      event(`event_site_filter_coverType`);
    }
  };

  const handleRemoveFormatTag = (tag: TagItemType) => {
    setSelectedProductFormat((prev) =>
      prev.filter((prevSelectedFormatType) => prevSelectedFormatType !== tag.value)
    );
    setSelectedFormatTags((prev) =>
      prev.filter((prevSelectedFormatTag) => prevSelectedFormatTag.value !== tag.value)
    );
    event(`event_site_filter_formatType-remove`);
  };

  const handleRemovePageTag = (tag: TagItemType) => {
    setSelectedPageCount((prev) => prev.filter((prevPageCount) => prevPageCount !== tag.value));
    setSelectedPageTags((prev) =>
      prev.filter((prevSelectedPageTag) => prevSelectedPageTag.value !== tag.value)
    );
    event(`event_site_filter_pageCount-remove`);
  };

  const handleRemoveCoverTag = (tag: TagItemType) => {
    setSelectedCoverType((prev) =>
      prev.filter((prevSelectedCoverType) => prevSelectedCoverType !== tag.value)
    );
    setSelectedCoverTags((prev) =>
      prev.filter((prevSelectedCoverTag) => prevSelectedCoverTag.value !== tag.value)
    );
    event(`event_site_filter_coverType-remove`);
  };

  const handleChangeCategory = (category: string) => {
    setSelectedCategory(category);
    setCategoryType(category);

    switch (category) {
      case 'photobooks':
        setSelectedProducts(photobooks);
        break;
      // case 'wedding':
      //   setSelectedProducts(weddingProducts);
      //   break;
      // case 'detskaya':
      //   setSelectedProducts(detskayaProducts);
      //   break;
      case 'sale':
        setSelectedProducts(saleProducts);
        break;
      case 'calendars':
        setSelectedProducts(calendarProducts);
        break;
      case 'notebooks':
        setSelectedProducts(notebookProducts);
        break;
      case 'other':
        setSelectedProducts([...printsProducts, ...otherProducts]);
        break;

      // case 'traditions':
      //   setSelectedProducts(traditionsBook);
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {
    const itemsToSelect = getItemsByProperties({
      pageCounts: selectedPageCount,
      coverTypes: selectedCoverType,
      productFormats: selectedProductFormat,
    });

    const normalizedPageCount = selectedPageCount.join(',');
    const normalizedCoverType = selectedCoverType.join(',');
    const normalizedProductFormat = selectedProductFormat.join(',');

    setPageCount(normalizedPageCount);
    setCoverType(normalizedCoverType);
    setProductFormat(normalizedProductFormat);

    setItems(itemsToSelect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPageCount, selectedCoverType, selectedCategory, selectedProductFormat]);

  useEffect(() => {
    let isMounted = true;
    if (params && isMounted) {
      const {
        pageCount: pageCountFromUrl,
        coverType: coverTypeFromUrl,
        productFormat: productFormatFromUrl,
        category: categoryTypeFromUrl,
      } = params;

      if (
        typeof categoryTypeFromUrl === 'string' &&
        allowedProductCategories.indexOf(categoryTypeFromUrl) !== -1
      ) {
        handleChangeCategory(categoryTypeFromUrl);
      } else {
        handleChangeCategory('photobooks');
      }

      if (pageCountFromUrl && typeof pageCountFromUrl === 'string') {
        const parsedPageCount = pageCountFromUrl.split(',');

        parsedPageCount.forEach((item: string) => {
          if (allowedPageCounts.indexOf(item) !== -1) {
            handleSelectPageCount(item, true, true);
          }
        });
      }

      if (coverTypeFromUrl && typeof coverTypeFromUrl === 'string') {
        const parsedCoverType = coverTypeFromUrl.split(',');

        parsedCoverType.forEach((item: string) => {
          if (allowedCoverTypes.indexOf(item) !== -1) {
            handleSelectCoverType(item, true, true);
          }
        });
      }

      if (productFormatFromUrl && typeof productFormatFromUrl === 'string') {
        const parsedFormatType = productFormatFromUrl.split(',');

        parsedFormatType.forEach((item: string) => {
          if (allowedFormatTypes.indexOf(item) !== -1) {
            handleSelectFormatType(item, true, true);
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className={styles.header}>
        <Header>Все продукты</Header>
      </div>

      <Tabs tabs={tabs} selectedTab={selectedCategory} onSelectTab={handleChangeCategory} />

      {selectedCategory !== 'other' &&
        selectedCategory !== 'sale' &&
        selectedCategory !== 'calendars' &&
        selectedCategory !== 'notebooks' &&
        selectedCategory !== 'traditions' && (
          <>
            <div className={styles.filters}>
              <FilterMultiSelect
                title="Обложка"
                onChange={handleSelectCoverType}
                options={coverTypeFilterOptions}
                customClassName={styles.filter}
                selectedOptions={selectedCoverType}
              />

              <FilterMultiSelect
                title="Кол-во страниц"
                onChange={handleSelectPageCount}
                options={pageCountFilterOptions[selectedCategory]}
                customClassName={styles.filter}
                selectedOptions={selectedPageCount}
              />
              <FilterMultiSelect
                title="Формат"
                onChange={handleSelectFormatType}
                options={formatTypeFilterOptions}
                customClassName={styles.filterFormat}
                selectedOptions={selectedProductFormat}
              />
            </div>

            {(!!selectedCoverTags.length ||
              !!selectedPageTags.length ||
              !!selectedFormatTags.length) && (
              <div className={styles.selectedFilters}>
                {selectedCoverTags && (
                  <Tags selectedTags={selectedCoverTags} onTagRemove={handleRemoveCoverTag} />
                )}
                {selectedPageTags && (
                  <Tags selectedTags={selectedPageTags} onTagRemove={handleRemovePageTag} />
                )}
                {selectedFormatTags && (
                  <Tags selectedTags={selectedFormatTags} onTagRemove={handleRemoveFormatTag} />
                )}
              </div>
            )}
          </>
        )}
      <div className={styles.block}>
        <Products
          products={
            selectedCategory !== 'other' &&
            selectedCategory !== 'sale' &&
            selectedCategory !== 'calendars' &&
            selectedCategory !== 'notebooks' &&
            selectedCategory !== 'traditions' &&
            (!!selectedCoverTags.length || !!selectedPageTags.length || !!selectedFormatTags.length)
              ? items
              : selectedProducts
          }
        />
      </div>
      <ThinPromo
        title="Скидка 7% при покупке от 3-х фотокниг"
        text="Используйте промокод <b>ФОТОКНИГИ7</b> при оплате заказа."
      />
      <MiniServices header="Услуги" />
    </Layout>
  );
}

export default ProductsPage;
